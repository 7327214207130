import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { Wrapper, H2 } from '@raysync/common/components'
import intl from 'react-intl-universal'
import notFoundImage from '../images/404.svg'

const NotFoundWrapper = styled(Wrapper)`
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const NotFoundContainer = styled.div`
  text-align: center;
  img {
    width: 910px;
    height: 344px;
    display: block;
    margin: 85px auto 0;
  }
`
const Title = styled(H2)`
  font-size: 36px;
`
const Desc = styled.p`
  color: ${p => p.theme.color.text};
  line-height: 24px;
  font-size: ${p => p.theme.typography.text};
  margin-top: 30px;
`
const Button = styled.a`
  display: inline-block;
  padding: 0 56px;
  height: 60px;
  line-height: 60px;
  color: white;
  background: ${p => p.theme.color.secondary};
  box-shadow: 0px 14px 30px 0px rgba(253, 92, 31, 0.16);
  border-radius: 30px;
  margin: 45px auto 0;
  cursor: pointer;
`

class NotFound extends React.PureComponent {
  render() {
    const { location } = this.props
    return (
      <Layout pageType='notFound' location={location}>
        <NotFoundWrapper>
          <NotFoundContainer>
            <Title>{intl.get('notFound.title')}</Title>
            <Desc>{intl.get('notFound.desc')}</Desc>
            <Button href='/'>{intl.get('notFound.btn')}</Button>
            <img src={notFoundImage} alt='404 not found' />
          </NotFoundContainer>
        </NotFoundWrapper>
      </Layout>
    )
  }
}

export default NotFound
